import axios from 'axios';
import config from '../config/config';

export type LoginParams = {
  email: string;
  password: string;
};

const login = (params: LoginParams) =>
  axios.post(config.baseURL + config.apis.login, {
    username: params.email,
    password: params.password,
  });

const AuthAPI = {
  login,
};

export default AuthAPI;
