import { atom, useRecoilState } from 'recoil';

const promptState = atom({
  key: 'MDSDesignPromptState',
  default: {
    show: false,
    title: '',
    content: '',
    callback: (action: 'ok' | 'cancel') => {},
  },
});

const usePrompt = () => {
  const [_prompt, _setPrompt] = useRecoilState(promptState);
  const show = (
    title: string,
    content: string,
    callback: (action: 'ok' | 'cancel') => any,
  ) => {
    _setPrompt({
      show: true,
      title: title,
      content: content,
      callback: callback,
    });
  };

  const hide = (type: 'ok' | 'cancel') => {
    _prompt.callback(type);
    _setPrompt({
      show: false,
      title: '',
      content: '',
      callback: (action: 'ok' | 'cancel') => {},
    });
  };

  return { show, hide, value: _prompt };
};

export { usePrompt };
