import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../../libraries/mds_auth';
import {
  Alert,
  LoadingOverlay,
  PageTitle,
  Prompt,
  useQuery,
} from '../../../../libraries/mds_design_system';
import conversationAPI from '../../apis/conversations';
import InteractiveContexts from '../../contexts/InteractiveContext';
import ChatPanel from './ChatPanel';
import SourcePanel from './SourcePanel';
import UploadPanel from './UploadPanel';

const ChatPage = (): JSX.Element => {
  const openSourcePanel = useRecoilValue(InteractiveContexts.openSourcePanel);
  return (
    <>
      <PageTitle title="AlphaGPT" />
      <div className="flex h-full">
        <div className="w-2/12 overflow-y-auto overflow-x-hidden">
          <UploadPanel />
        </div>
        <div
          className={classNames({
            'w-5/12': openSourcePanel,
            'w-10/12': !openSourcePanel,
          })}
        >
          <ChatPanel />
        </div>
        <div
          className={classNames({
            'w-5/12': openSourcePanel,
            hidden: !openSourcePanel,
          })}
        >
          <SourcePanel />
        </div>
      </div>
      <Tooltip id="my-tooltip" />
      <Prompt />
    </>
  );
};

export default ChatPage;
