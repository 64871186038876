import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../../../libraries/mds_auth';
import {
  Button,
  useMutation,
  useQuery,
} from '../../../../../libraries/mds_design_system';
import SourceAPI from '../../../apis/sources';

type SourcePreviewProps = {
  sourceId: number;
  page: number;
};

const SourcePreview = (props: SourcePreviewProps) => {
  console.log(props);
  const token = useRecoilValue(AuthContext.userTokenState);
  const sourceQuery = useQuery(SourceAPI.get, {
    params: { id: props.sourceId, token },
    reloadOn: [props.sourceId, props.page],
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  if (sourceQuery.loading) return <div></div>;
  if (sourceQuery.error) return <div></div>;
  if (!sourceQuery.response) return <div></div>;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
      <Viewer
        fileUrl={sourceQuery.response.file}
        plugins={[defaultLayoutPluginInstance]}
        initialPage={props.page - 1}
      />
    </Worker>
  );
};

export default SourcePreview;
