import _ from 'lodash';
import { useState } from 'react';

const useForm = (defaultValue = {}, onChange?: (data: any) => any) => {
  const [_data, _setData] = useState<{ [index: string]: any }>(defaultValue);

  const getData = (key?: string, defaultValue: any = undefined) => {
    if (key) return _.get(_data, key, defaultValue);
    return _data;
  };

  const setData = (key: string, value: any) => {
    const newData = _.cloneDeep(_data);
    _.set(newData, key, value);
    _setData(newData);
    if (onChange) onChange(newData);
  };

  const initData = (data: { [index: string]: any }) => {
    _setData(data);
  };

  return { setData, getData, initData };
};

export { useForm };
