type MessageViewProps = {
  text: string;
};

const MessageViewUser = (props: MessageViewProps) => {
  return (
    <div className="flex space-x-4 my-2 mr-4">
      <img
        className="inline-block h-9 w-9 rounded-full"
        src={require('../../../../assets/account_circle.png')}
        alt=""
      />
      <div className="flex-grow">
        <div className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-white">
          {props.text}
        </div>
      </div>
    </div>
  );
};

export default MessageViewUser;
