type TextBoxProps = {
  value: string;
  onChange: any;
  onKeyDown?: any;
};

const TextBox = (props: TextBoxProps) => {
  return (
    <div className="flex flex-grow items-stretch overflow-hidden mt-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 bg-white">
      <textarea
        id="about"
        name="about"
        rows={3}
        className="block w-full border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        onChange={(event: any) => props.onChange(event)}
        value={props.value}
        onKeyDown={props.onKeyDown}
      ></textarea>
    </div>
  );
};

export default TextBox;
