import * as React from 'react';
import { Icon } from '../libraries/mds_design_system';

const sideMenuConfig = [
  {
    label: 'NexusGPT',
    icon: <Icon icon="assistant" />,
    link: '/app/nexus-gpt/conversations',
  },
];

export default sideMenuConfig;
