import moment from 'moment';
import MessageViewBot from './bot';
import MessageViewBotSuggest from './bot_suggest';
import MessageViewTexting from './texting';
import MessageViewUser from './user';

type MessageViewProps = {
  text: string;
  type: 'user' | 'bot' | 'texting' | 'suggest';
  sources?: Array<any>;
  onClickLike?: (state: string) => any;
  message?: any;
  animation?: boolean;
};

const MessageView = (props: MessageViewProps) => {
  if (props.type === 'texting') {
    return <MessageViewTexting />;
  }

  if (props.type === 'user') {
    return <MessageViewUser text={props.text} />;
  }

  if (props.type === 'bot') {
    return <MessageViewBot {...props} />;
  }

  if (props.type === 'suggest') {
    return <MessageViewBotSuggest {...props} onClick={() => {}} />;
  }

  return <div />;
};

export default MessageView;
