import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Icon, Tab } from '../../../../../libraries/mds_design_system';
import InteractiveContexts from '../../../contexts/InteractiveContext';
import SourcePreview from './source_preview';

const SourcePanel = () => {
  const setOpenSourcePanel = useSetRecoilState(
    InteractiveContexts.openSourcePanel,
  );
  const openSourcePanel = useRecoilValue(InteractiveContexts.openSourcePanel);

  return (
    <div className="h-full max-h-full flex flex-col pt-6">
      <div className="mx-6 flex justify-between">
        <div className="text-lg leading-7 font-medium text-gray-900">
          Source documents
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setOpenSourcePanel(undefined)}
        >
          <Icon icon="close" className="text-gray-400" />
        </div>
      </div>
      <div className="h-full overflow-auto">
        {openSourcePanel && (
          <SourcePreview
            sourceId={openSourcePanel.sourceId}
            page={openSourcePanel.page}
          />
        )}
      </div>
    </div>
  );
};

export default SourcePanel;
