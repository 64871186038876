import { BeatLoader } from 'react-spinners';

const MessageViewTexting = () => {
  return (
    <div className="flex space-x-4 my-2 mr-4 items-center">
      <img
        className="inline-block h-9 w-9 rounded-full"
        src={require('../../../../assets/NexusGPTIcon.png')}
        alt=""
      />
      <div className="flex-grow">
        <BeatLoader color="#949B26" loading />
      </div>
    </div>
  );
};

export default MessageViewTexting;
