import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../../../libraries/mds_auth';
import {
  Icon,
  useMutation,
  usePrompt,
  useQuery,
} from '../../../../../libraries/mds_design_system';
import SourceAPI from '../../../apis/sources';

type SourceListProps = {
  sourceForm: any;
  time: any;
};

const SourceList = (props: SourceListProps) => {
  const token = useRecoilValue(AuthContext.userTokenState);
  const sources = useQuery(SourceAPI.all, {
    params: { token, page: 0, searchText: '' },
    reloadOn: [props.time],
  });
  const deleteMutation = useMutation(SourceAPI.destroy, {
    onSuccess: () => sources.call(),
  });
  const selectedSources = props.sourceForm.getData('sources');
  return (
    sources.response &&
    sources.response
      .sort(
        (a: any, b: any) =>
          selectedSources.includes(b.id) - selectedSources.includes(a.id),
      )
      .map((source: any) => (
        <SourceListItem
          key={source.id}
          source={source}
          selected={selectedSources.includes(source.id)}
          onChange={value => {
            if (value) {
              props.sourceForm.setData('sources', [
                source.id,
                ...selectedSources,
              ]);
            } else {
              props.sourceForm.setData(
                'sources',
                selectedSources.filter((s: any) => s !== source.id),
              );
            }
          }}
          onDelete={() => deleteMutation.call({ id: source.id, token: token })}
        />
      ))
  );
};

type SourceListItemProps = {
  source: any;
  selected: boolean;
  onChange: (value: boolean) => any;
  onDelete: () => any;
};

const SourceListItem = (props: SourceListItemProps) => {
  const prompt = usePrompt();
  return (
    <div
      className="relative flex gap-x-3 w-full"
      data-tooltip-id="my-tooltip"
      data-tooltip-content={props.source.name}
    >
      <div className="flex h-6 items-center">
        <input
          id={props.source.id}
          name="comments"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-nexus-600 focus:ring-nexus-600"
          checked={props.selected}
          onChange={event => props.onChange(event.target.checked)}
        />
      </div>
      <div className="text-sm leading-6 grow truncate">
        <label
          htmlFor={props.source.id}
          className="text-sm leading-5 font-normal text-gray-900"
        >
          {props.source.name}
        </label>
      </div>
      {props.source.status !== 'default_document' ? (
        <button
          onClick={() =>
            prompt.show(
              'This action cannot be undone',
              'Are you sure that you want to delete this source? The source will be permanently deleted from the system',
              (action: 'ok' | 'cancel') => {
                if (action === 'ok') {
                  props.onDelete();
                }
              },
            )
          }
        >
          <Icon icon="delete" />
        </button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SourceList;
