import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../../../libraries/mds_auth';
import {
  Button,
  LoadingOverlay,
  useForm,
  useMutation,
  usePrompt,
  useQuery,
} from '../../../../../libraries/mds_design_system';
import conversationAPI from '../../../apis/conversations';
import messageAPI from '../../../apis/messages';
import TextBox from '../../../components/molecules/TextBox';
import ActionBottomBar from './ActionBottomBar';
import MessageView from './MessageView';
import Tutorial from './Tutorial';

const ChatPanel = () => {
  const params = useParams();
  const token = useRecoilValue(AuthContext.userTokenState);
  const prompt = usePrompt();
  const [loadTime] = useState(moment());
  const [newMessage, setNewMessage] = useState<string | undefined>(undefined);
  const messageForm = useForm({ type: 'user', text: '' });
  const messageQuery = useQuery(messageAPI.get, {
    params: { id: params.id, token },
    cacheLastRequest: true,
    onSuccess: () => setNewMessage(undefined),
  });

  const createMessage = useMutation(messageAPI.create, {
    defaultParams: { token, conversationId: params.id },
  });
  const patchMessage = useMutation(messageAPI.patch, {
    defaultParams: { token, conversationId: params.id },
  });
  const clearMessages = useMutation(conversationAPI.clearMessages, {
    defaultParams: { token, id: params.id },
  });

  const sendMessage = (message: string) => {
    createMessage
      .call({ data: { type: 'user', text: message } })
      .then(() => messageQuery.call());
    setNewMessage(message);
    messageForm.initData({ type: 'user', text: '' });
  };
  const messages = _.get(messageQuery, 'response.messages', []);

  return (
    <div className="w-full h-full bg-gray-50 px-6 flex justify-center relative">
      {messageQuery.loading && !messageQuery.response && <LoadingOverlay />}
      <div className="h-full w-full max-w-4xl flex flex-col">
        <div className="flex-grow flex flex-col-reverse overflow-auto">
          {messages.length ? (
            <ActionBottomBar
              onRetryClick={() => {
                const lastMessage = messageQuery.response.messages.find(
                  (message: any) => message.type === 'user',
                );
                sendMessage(lastMessage.text);
              }}
              onDeleteClick={() => {
                prompt.show(
                  'Delete Thread',
                  'All the previous prompts and answers will be deleted. Make sure you copy them into a safe place before deleting.',
                  (action: string) => {
                    if (action === 'ok')
                      clearMessages.call({}).then(() => messageQuery.call());
                  },
                );
              }}
            />
          ) : (
            <div />
          )}
          {createMessage.loading && <MessageView text="..." type="texting" />}
          {newMessage && <MessageView text={newMessage} type="user" />}
          {messages.map((message: any, index: number) => (
            <MessageView
              key={message.id}
              message={message}
              text={message.text}
              type={message.type}
              sources={message.sources}
              onClickLike={(status: string) =>
                patchMessage
                  .call({ id: message.id, data: { like: status } })
                  .then(() => messageQuery.call())
              }
              animation={
                moment(message.created_at).isAfter(loadTime) && index === 0
              }
            />
          ))}
          {messageQuery.response && (
            <Tutorial
              onClick={(message: string) => sendMessage(message)}
              animation={messages.length == 0}
            />
          )}
        </div>
        <div className="col-span-full flex items-end space-x-2 py-4 border-t">
          <TextBox
            value={messageForm.getData('text')}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                sendMessage(messageForm.getData('text'));
              }
            }}
            onChange={(event: any) =>
              messageForm.setData('text', event.target.value)
            }
          />
          <Button onClick={() => sendMessage(messageForm.getData('text'))}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatPanel;
