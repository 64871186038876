import { AxiosError, AxiosResponse } from 'axios';
import { SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';

const useMutation = (
  apiRequest: (params: any) => Promise<any>,
  options?: {
    onSuccess?: (response: AxiosResponse) => void;
    defaultParams?: any;
    toast?: {
      successMessage?: string;
      errorMessage?: string | boolean | ((error: AxiosError) => string);
    };
  },
) => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const call = async (params: any) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      setResponse(undefined);
      setError(undefined);

      apiRequest({ ...options?.defaultParams, ...params })
        .then((_response: AxiosResponse) => {
          setResponse(_response.data);
          options?.onSuccess?.(_response.data);
          if (options?.toast?.successMessage) {
            toast.success(options?.toast?.successMessage);
          }
          resolve(_response);
        })
        .catch((error: AxiosError) => {
          setError(error);
          if (options?.toast?.errorMessage) {
            if (options?.toast?.errorMessage === true) {
              toast.error(error?.message);
            } else if (typeof options?.toast?.errorMessage == 'string') {
              toast.error(options?.toast?.errorMessage);
            } else {
              toast.error(options?.toast?.errorMessage(error));
            }
          }
          reject(error);
        })
        .finally(() => setLoading(false));
    });

  return { response, error, loading, call };
};

export { useMutation };
