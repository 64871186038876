type MessageViewProps = {
  text: string;
  sources?: Array<any>;
  onClick: () => any;
};

const MessageViewBotSuggest = (props: MessageViewProps) => {
  return (
    <div className="flex space-x-4 my-2 mr-4">
      <img
        className="inline-block h-9 w-9 rounded-full"
        src={require('../../../../assets/NexusGPTIcon.png')}
        alt=""
      />
      <div
        className="flex-grow cursor-pointer underline"
        onClick={() => props.onClick()}
      >
        <div className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100">
          {props.text}
        </div>
      </div>
    </div>
  );
};

export default MessageViewBotSuggest;
