import axios from 'axios';
import configs from '../config/config';

const apiConfig = {
  path: configs.baseURL + configs.apis.conversations,
};

const all = (
  params: { page: number; searchText: string; token: string } = {
    page: 0,
    searchText: '',
    token: '',
  },
) => {
  return axios.get(apiConfig.path, {
    params: {
      page: params.page + 1,
      search: params.searchText.length ? params.searchText : null,
    },
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const get = (params: { id?: string; token: string }) => {
  if (!params.id) return Promise.reject('Missing conversation id');
  return axios.get(apiConfig.path + '/' + params.id, {
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const create = (params: { data: any; token: string }) =>
  axios.post(apiConfig.path, params.data, {
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });

const patch = (params: { id: string; data: any; token: string }) => {
  return axios.patch(apiConfig.path + '/' + params.id, params.data, {
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const updateSources = (params: { id: string; data: any; token: string }) => {
  return axios.put(
    apiConfig.path + '/' + params.id + '/selected_sources',
    params.data,
    {
      headers: {
        Authorization: `Token ${params.token}`,
      },
    },
  );
};

const clearMessages = (params: { id: string; token: string }) => {
  return axios.post(`${apiConfig.path}/${params.id}/clear`, null, {
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const conversationAPI = {
  all,
  create,
  get,
  patch,
  updateSources,
  clearMessages,
};

export default conversationAPI;
