import { useSetRecoilState } from 'recoil';
import { Icon } from '../../../../../../../libraries/mds_design_system';
import InteractiveContexts from '../../../../../contexts/InteractiveContext';

type DocumentSourceProps = {
  sourceId: string;
  fileName: string;
  page: number;
};

const DocumentSource = (props: DocumentSourceProps) => {
  const setOpenSourcePanel = useSetRecoilState(
    InteractiveContexts.openSourcePanel,
  );
  return (
    <div className="text-sm font-normal text-nexus-700 break-words">
      <Icon icon="check_circle" size={10} className="align-middle" /> Analysed:{' '}
      <span
        className="underline cursor-pointer"
        onClick={() =>
          setOpenSourcePanel({ sourceId: props.sourceId, page: props.page })
        }
      >
        {props.fileName}, page {props.page}
      </span>
    </div>
  );
};

export default DocumentSource;
