const config = {
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://alpha-gpt-production.app.podder.ai/nexus_gpt/api'
      : 'http://localhost:5011/nexus_gpt/api',
  apis: {
    conversations: '/conversations',
    sources: '/sources',
    messages: '/messages',
  },
};

export default config;
