import * as React from 'react';
import { useState } from 'react';

type AppBarProps = {
  items?: Array<AppBarItemProps>;
};

type AppBarItemProps = {
  label: string;
  onClick: () => any;
};

const AppBar = (props: AppBarProps): JSX.Element => {
  const [openUserMenu, setOpenUserMenu] = useState(false);
  return (
    <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
      <div className="flex flex-1 justify-between px-4">
        <div className="flex flex-1"></div>
        <div className="ml-4 flex items-center md:ml-6">
          <div className="relative ml-3">
            <div>
              <button
                type="button"
                className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setOpenUserMenu(!openUserMenu)}
              >
                <span className="sr-only">Open user menu</span>
                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                  <span className="text-sm font-medium leading-none text-white">
                    N
                  </span>
                </span>
              </button>
            </div>

            {openUserMenu && props.items && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex={-1}
              >
                {props.items.map(item => (
                  <button
                    onClick={() => item.onClick()}
                    className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-2"
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AppBar, type AppBarItemProps };
