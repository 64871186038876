import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../../../libraries/mds_auth';
import {
  Button,
  Icon,
  Input,
  InputFile,
  LoadingOverlay,
  useForm,
  useMutation,
  useQuery,
} from '../../../../../libraries/mds_design_system';
import conversationAPI from '../../../apis/conversations';
import SourceAPI from '../../../apis/sources';
import SourceList from './source_list';

const UploadPanel = () => {
  const [time, setTime] = useState(new Date());
  const token = useRecoilValue(AuthContext.userTokenState);
  const fileForm = useForm({ type: 'pdf' });
  const urlForm = useForm({ type: 'web_url' });
  const sourceForm = useForm({ sources: [] }, data => {
    updateSource.call({
      data: data,
      id: params.id,
      token,
    });
  });
  const params = useParams();
  useQuery(conversationAPI.get, {
    params: { id: params.id, token },
    onSuccess: response =>
      sourceForm.setData(
        'sources',
        response.sources.map((item: any) => item.id),
      ),
  });
  const updateSource = useMutation(conversationAPI.updateSources);
  const createSource = useMutation(SourceAPI.create, {
    onSuccess: (response: any) => {
      fileForm.initData({ type: 'pdf' });
      urlForm.initData({ type: 'web_url', url: '' });
      sourceForm.setData('sources', [
        ...sourceForm.getData('sources'),
        response.id,
      ]);
      setTime(new Date());
    },
    toast: {
      successMessage: 'Successful file uploading and pre-processing.',
      errorMessage:
        'There is a problem pre-processing the source file. Please try again.',
    },
  });
  return (
    <div className="bg-white px-3.5 py-6 space-y-6 relative">
      {createSource.loading && <LoadingOverlay />}
      <div>
        <div className="text-xl leading-7 font-bold text-gray-900">
          Document Store
        </div>
        <div className="mt-6 text-base leading-6 font-semibold text-gray-900 pb-0.5">
          Select Reference Sources
        </div>
        <div className="text-xs leading-4 font-normal">
          Please choose the files and web sources to cross-analyse.
        </div>
        <div className="mt-4">
          <SourceList sourceForm={sourceForm} time={time} />
        </div>
        <div className="h-0.5 w-full bg-gray-300 mt-4" />
      </div>
      <div className="relative">
        <div className="text-base leading-5 font-medium text-gray-900 pb-0.5">
          Upload Reference File Source(s)
        </div>
        <div className="text-xs leading-4 font-normal">
          Enrich your analysis by uploading your private documents.
        </div>
        <div className="mt-5">
          <InputFile
            value={fileForm.getData('file')}
            onChange={file => fileForm.setData('file', file)}
          />
        </div>
        <div className="mt-2.5">
          <Button
            fullWidth
            onClick={() =>
              createSource.call({
                token,
                data: fileForm.getData(),
              })
            }
          >
            Upload
          </Button>
        </div>
      </div>
      <div>
        <div className="text-base leading-5 font-medium text-gray-900 pb-0.5">
          Add Web Source(s)
        </div>
        <div className="text-xs leading-4 font-normal">
          Insert specific URLs for a more concentrated analysis in addition to a
          broad internet search.
        </div>
        <div className="mt-5">
          <Input
            value={urlForm.getData('url')}
            onChange={event => urlForm.setData('url', event.target.value)}
            placeholder="Enter the URL here"
            autoComplete="off"
          />
        </div>
        <div className="mt-2.5">
          <Button
            fullWidth
            onClick={() =>
              createSource.call({
                token,
                data: urlForm.getData(),
              })
            }
          >
            Add as Reference
          </Button>
        </div>
      </div>
      <div className="space-y-1">
        <div className="h-0.5 w-full bg-gray-300 mb-7" />
        <a
          className="flex flex-row space-x-2 text-sm leading-4 font-medium text-nexus-600 cursor-pointer"
          href="https://nexusfrontier.hubspotpagebuilder.com/faqs"
          target="_blank"
        >
          <Icon icon="info" size={10} className="align-middle" />
          <div>Help and FAQ</div>
        </a>
        <a
          className="flex flex-row space-x-2 text-sm leading-4 font-medium text-nexus-600 cursor-pointer"
          href="https://share.hsforms.com/17ooRgpRZRkipaxfe54jqUQ5aqcs"
          target="_blank"
        >
          <Icon icon="sms" size={10} className="align-middle" />
          <div>Leave feedback</div>
        </a>
        <a
          className="flex flex-row space-x-2 text-sm leading-4 font-medium text-nexus-600 cursor-pointer"
          href="https://meetings.hubspot.com/lawrence-billson/intro-meeting"
          target="_blank"
        >
          <Icon icon="call" size={10} className="align-middle" />
          <div>Contact us</div>
        </a>
      </div>
    </div>
  );
};

export default UploadPanel;
