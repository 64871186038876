import { atom } from 'recoil';

const openSourcePanel = atom<any>({
  key: 'OpenSourcePanel',
  default: undefined,
});

const InteractiveContexts = {
  openSourcePanel,
};

export default InteractiveContexts;
