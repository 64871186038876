import { Icon } from '../../../../../../../libraries/mds_design_system';

type InternetSourceProps = {
  url: string;
};

const InternetSource = (props: InternetSourceProps) => {
  return (
    <div className="text-sm font-normal text-nexus-700 break-all">
      <Icon icon="check_circle" size={10} className="align-middle" /> Analysed:{' '}
      <span
        className="underline cursor-pointer"
        onClick={() => window.open(props.url)}
      >
        {props.url}
      </span>
    </div>
  );
};

export default InternetSource;
