import * as React from 'react';
import { TopLogo } from '../../molecules/TopLogo';
import { AppBar, type AppBarItemProps } from '../../organisms/AppBar';
import { SideMenu } from '../../organisms/SideMenu';

type ItemProps = {
  label: string;
  icon?: any;
  link: string;
};

type AppLayoutProps = {
  minimized?: boolean;
  sideMenuItems: Array<ItemProps>;
  children: any;
  logo?: string;
  disableAppBar?: boolean;
  appBarItems?: Array<AppBarItemProps>;
  type?: 'sidebar' | 'header';
};

const AppLayout = (props: AppLayoutProps): JSX.Element => {
  if (props.type === 'sidebar') {
    return (
      <>
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-56 md:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <TopLogo logo={props.logo} />
            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                <SideMenu
                  items={props.sideMenuItems}
                  minimized={props.minimized}
                />
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-56 h-screen">
          {!props.disableAppBar && <AppBar items={props.appBarItems} />}
          <main className="flex-1 overflow-auto min-w-full">
            {props.children}
          </main>
        </div>
      </>
    );
  }

  if (props.type === 'header') {
    return (
      <div className="h-screen flex flex-col">
        <nav className="border-b border-gray-200 bg-white">
          <div className="mx-auto">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <TopLogo logo={props.logo} />
                </div>
              </div>
              <AppBar items={props.appBarItems} />
            </div>
          </div>
        </nav>

        <main className="flex-1 overflow-y-auto min-w-full grow">
          {props.children}
        </main>
      </div>
    );
  }

  return <div />;
};

AppLayout.defaultProps = {
  type: 'sidebar',
};

export { AppLayout, type AppLayoutProps };
