import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { useSetRecoilState } from 'recoil';
import { Icon } from '../../../../../../libraries/mds_design_system';
import InteractiveContexts from '../../../../contexts/InteractiveContext';
import DocumentSource from './Source/document';
import InternetSource from './Source/internet';
import MessageViewTexting from './texting';
import MessageViewUser from './user';

type MessageViewProps = {
  text: string;
  sources?: Array<any>;
  animation?: boolean;
  onClickLike?: (state: string) => any;
  message?: any;
};

const CHAR_PER_SEC = 200;
const REFRESH_INTERVAL_MS = 20;

const MessageViewBot = (props: MessageViewProps) => {
  const [expand, setExpand] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count < props.text.length)
        setCount(count + CHAR_PER_SEC / REFRESH_INTERVAL_MS);
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  });

  return (
    <div className="flex space-x-4 my-2 mr-4">
      <img
        className="inline-block h-9 w-9 rounded-full"
        src={require('../../../../assets/NexusGPTIcon.png')}
        alt=""
      />
      <div className="flex-grow">
        <div
          className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: props.animation
              ? props.text.substring(0, count)
              : props.text,
          }}
        />
        {props.sources && (count >= props.text.length || !props.animation) && (
          <div
            className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 underline cursor-pointer"
            onClick={() => setExpand(!expand)}
          >
            {expand ? (
              'Hide detail'
            ) : (
              <>
                <span className="font-bold">Trace It: </span>
                Click to see how we derived this answer from different sources.
              </>
            )}
          </div>
        )}
        {expand && (
          <>
            <div className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 space-y-4">
              <div className="font-bold underline">From Uploaded Sources:</div>
              <div className="space-y-2">
                {_.get(props.sources, 'sources_output.used_sources', []).map(
                  (source: any, index: number) =>
                    source.info.type === 'pdf' ? (
                      <DocumentSource
                        key={index}
                        sourceId={source.info.source_id}
                        fileName={source.info.file_name}
                        page={source.info.page}
                      />
                    ) : (
                      <InternetSource key={index} url={source.info.url} />
                    ),
                )}
              </div>
              <div
                className="py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: _.get(props.sources, 'sources_output.response', ''),
                }}
              />
            </div>
            <div className="px-3 py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 space-y-4">
              <div className="font-bold underline">
                <b>From Online Search:</b>
              </div>
              <div className="space-y-2">
                {_.get(props.sources, 'internet_output.used_web_sites', []).map(
                  (site: any, index: number) => (
                    <InternetSource key={index} url={site.info} />
                  ),
                )}
              </div>
              <div
                className="py-2 text-gray-900 text-sm leading-5 font-normal bg-nexus-100 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: _.get(props.sources, 'internet_output.response', ''),
                }}
              />
            </div>
          </>
        )}
        <div className="flex justify-between">
          <div />
          <div className="flex space-x-1">
            <button
              onClick={() =>
                props.message?.like === 'like'
                  ? props.onClickLike?.('none')
                  : props.onClickLike?.('like')
              }
            >
              <Icon
                className="text-base text-nexus-400"
                icon="thumb_up"
                fill={props.message?.like === 'like'}
              />
            </button>
            <button
              onClick={() =>
                props.message?.like === 'dislike'
                  ? props.onClickLike?.('none')
                  : props.onClickLike?.('dislike')
              }
            >
              <Icon
                className="text-base text-nexus-400"
                icon="thumb_down"
                fill={props.message?.like === 'dislike'}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageViewBot;
