const config = {
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://alpha-gpt-production.app.podder.ai/api'
      : 'http://localhost:5011/api',
  apis: {
    users: '/users',
    login: '/auth/token',
  },
};

export default config;
