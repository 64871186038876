import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export type useQueryType = {
  error?: AxiosError;
  loading?: boolean;
  response?: any;
  call: () => any;
};

const useQuery = (
  apiRequest: (params?: any) => any,
  options?: {
    params?: any;
    onSuccess?: (response: any) => any;
    reloadOn?: Array<any>;
    cacheLastRequest?: boolean;
    toast?: {
      successMessage?: string;
      errorMessage?: string | boolean;
    };
  },
): useQueryType => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const call = () => {
    setLoading(true);
    setError(undefined);
    if (!options?.cacheLastRequest) setResponse(undefined);

    apiRequest(options?.params)
      .then((_response: AxiosResponse) => {
        setResponse(_response.data);
        options?.onSuccess?.(_response.data);
        if (options?.toast?.successMessage) {
          toast.success(options?.toast?.successMessage);
        }
      })
      .catch((error: AxiosError) => {
        setError(error);
        if (options?.toast?.errorMessage) {
          if (options?.toast?.errorMessage === true) {
            toast.error(error?.message);
          } else {
            toast.error(options?.toast?.errorMessage);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => call(), options?.reloadOn || []);

  return { response, error, loading, call };
};

export { useQuery };
