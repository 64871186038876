import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState } from 'recoil';
import sideMenuConfig from './configs/sidemenu';
import {
  AuthContext,
  MDSAuthRoutes,
  MDSUserRoutes,
} from './libraries/mds_auth';
import { AppLayout } from './libraries/mds_design_system';
import NexusGPTRoutes from './use_cases/nexus_gpt';

function App() {
  const [userToken, setUserToken] = useRecoilState(AuthContext.userTokenState);
  if (userToken) {
    return (
      <>
        <div className="App">
          <Routes>
            <Route
              path="/app"
              element={
                <AppLayout
                  sideMenuItems={sideMenuConfig}
                  logo={require('./use_cases/nexus_gpt/assets/NexusGPTLogo.png')}
                  appBarItems={[
                    { label: 'Logout', onClick: () => setUserToken(null) },
                  ]}
                  type="header"
                >
                  <Outlet />
                </AppLayout>
              }
            >
              <Route path="nexus-gpt/*" element={<NexusGPTRoutes />} />
              <Route
                path="admin/WBSMqSRBUy5UZRvt/users/*"
                element={<MDSUserRoutes />}
              />
            </Route>
            <Route
              path="*"
              element={<Navigate to="/app/nexus-gpt/conversations" />}
            />
          </Routes>
        </div>
        <ToastContainer theme="colored" />
      </>
    );
  } else {
    return (
      <>
        <div className="App">
          <Routes>
            <Route path="/auth/*" element={<MDSAuthRoutes />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Routes>
        </div>
        <ToastContainer theme="colored" />
      </>
    );
  }
}

export default App;
