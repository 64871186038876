import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChatPage from './pages/chat';
import { ConversationListPage } from './pages/conversations';

const NexusGPTRoutes = () => {
  return (
    <Routes>
      <Route path="conversations" element={<ConversationListPage />} />
      <Route path="conversations/:id" element={<ChatPage />} />
    </Routes>
  );
};

export default NexusGPTRoutes;
