import { useEffect, useState } from 'react';
import MessageViewBot from '../MessageView/bot';
import MessageViewBotSuggest from '../MessageView/bot_suggest';
import MessageViewTexting from '../MessageView/texting';
import prompts from './prompts.json';

type TutorialProps = {
  animation?: boolean;
  onClick: (message: string) => any;
};

const Tutorial = (props: TutorialProps) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 10) setCount(count + 1);
    }, 1000);

    return () => clearInterval(interval);
  });
  return (
    <>
      {props.animation && count <= 6 && <MessageViewTexting />}
      {/*{prompts.suggested_prompts*/}
      {/*  .slice(0, props.animation ? Math.max(count - 4, 0) : 3)*/}
      {/*  .reverse()*/}
      {/*  .map(prompt => (*/}
      {/*    <MessageViewBotSuggest*/}
      {/*      key={prompt.id}*/}
      {/*      text={prompt.question}*/}
      {/*      onClick={() => props.onClick(prompt.question)}*/}
      {/*    />*/}
      {/*  ))}*/}
      {(!props.animation || count > 1) && (
        <MessageViewBot text={prompts.welcome_message} />
      )}
    </>
  );
};

export default Tutorial;
