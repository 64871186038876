import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../../libraries/mds_auth';
import { LoadingOverlay, useQuery } from '../../../libraries/mds_design_system';
import conversationAPI from '../apis/conversations';
import moduleConfig from './config';

const ConversationListPage = () => {
  const token = useRecoilValue(AuthContext.userTokenState);
  const navigate = useNavigate();
  const { loading } = useQuery(conversationAPI.all, {
    params: {
      token,
      page: 0,
      searchText: '',
    },
    onSuccess: response => {
      const { results } = response;
      if (results.length) {
        const result = results[0];
        navigate(`${moduleConfig.baseURL}/conversations/${result.id}`);
      }
    },
  });
  return <div>{loading && <LoadingOverlay />}</div>;
};

export { ConversationListPage };
