import { Icon } from '../../../../../../libraries/mds_design_system';

type ActionBottomBarProps = {
  onRetryClick: () => any;
  onDeleteClick: () => any;
};

const ActionBottomBar = (props: ActionBottomBarProps) => {
  return (
    <div className="w-full flex flex-row justify-center py-2 space-x-2">
      <button
        type="button"
        className="flex flex-row items-center rounded bg-white px-2 py-1 text-xs font-semibold text-nexus-600 shadow-sm ring-1 ring-inset ring-nexus-600 hover:bg-gray-50"
        onClick={() => props.onRetryClick()}
      >
        Retry Prompt <Icon icon="refresh" />
      </button>
      <button
        type="button"
        className="flex flex-row items-center rounded bg-white px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-600 hover:bg-gray-50"
        onClick={() => props.onDeleteClick()}
      >
        Delete Thread <Icon icon="delete" />
      </button>
    </div>
  );
};

export default ActionBottomBar;
