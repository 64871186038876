import axios from 'axios';
import configs from '../config/config';

const apiConfig = {
  path: configs.baseURL + configs.apis.conversations,
};

const all = (
  params: {
    page: number;
    searchText: string;
    token: string;
    conversationId: string;
  } = {
    page: 0,
    searchText: '',
    token: '',
    conversationId: '',
  },
) => {
  return axios.get(`${apiConfig.path}/${params.conversationId}/messages`, {
    params: {
      page: params.page + 1,
      search: params.searchText.length ? params.searchText : null,
    },
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const get = (params: { id?: string; token: string }) => {
  if (!params.id) return Promise.reject('Missing conversation id');
  return axios.get(apiConfig.path + '/' + params.id, {
    headers: {
      Authorization: `Token ${params.token}`,
    },
  });
};

const create = (params: { data: any; token: string; conversationId: string }) =>
  axios.post(
    `${apiConfig.path}/${params.conversationId}/messages`,
    params.data,
    {
      headers: {
        Authorization: `Token ${params.token}`,
      },
    },
  );

const patch = (params: {
  conversationId: string;
  id: string;
  data: any;
  token: string;
}) => {
  return axios.patch(
    `${apiConfig.path}/${params.conversationId}/messages/${params.id}`,
    params.data,
    {
      headers: {
        Authorization: `Token ${params.token}`,
      },
    },
  );
};

const messageAPI = {
  all,
  create,
  get,
  patch,
};

export default messageAPI;
